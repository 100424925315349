// obtain plugin
var cc = initCookieConsent();

 // run plugin with your configuration
 cc.run({
    current_lang: document.documentElement.getAttribute('lang'),
    autoclear_cookies: true,                   // default: false
    page_scripts: true,                        // default: false

    // mode: 'opt-in'                          // default: 'opt-in'; value: 'opt-in' or 'opt-out'
    // delay: 0,                               // default: 0
    // auto_language: '',                      // default: null; could also be 'browser' or 'document'
    // autorun: true,                          // default: true
    // force_consent: false,                   // default: false
    // hide_from_bots: true,                   // default: true
    // remove_cookie_tables: false             // default: false
    // cookie_name: 'cc_cookie',               // default: 'cc_cookie'
    // cookie_expiration: 182,                 // default: 182 (days)
    // cookie_necessary_only_expiration: 182   // default: disabled
    // cookie_domain: location.hostname,       // default: current domain
    // cookie_path: '/',                       // default: root
    // cookie_same_site: 'Lax',                // default: 'Lax'
    // use_rfc_cookie: false,                  // default: false
    // revision: 0,                            // default: 0

    onFirstAction: function(user_preferences, cookie){
        // callback triggered only once on the first accept/reject action
    },

    onAccept: function (cookie) {
        // callback triggered on the first accept/reject action, and after each page load
    },

    onChange: function (cookie, changed_categories) {
        // callback triggered when user changes preferences after consent has already been given
    },

    languages: {
        'uk': {
            consent_modal: {
                title: 'Ми використовуємо файли cookie!',
                description: 'Привіт, цей веб-сайт використовує основні файли cookie для забезпечення його належної роботи, а також файли cookie для відстеження, щоб зрозуміти, як ви взаємодієте з ним. Останні будуть встановлені тільки після вашої згоди. <button type="button" data-cc="c-settings" class="cc-link">Дозвольте мені вибрати</button>',
                primary_btn: {
                    text: 'Приймаємо всіх',
                    role: 'accept_all'              // 'accept_selected' or 'accept_all'
                },
                secondary_btn: {
                    text: 'Відкинути все',
                    role: 'accept_necessary'        // 'settings' or 'accept_necessary'
                }
            },
            settings_modal: {
                title: 'Налаштування файлів cookie',
                save_settings_btn: 'Зберегти налаштування',
                accept_all_btn: 'Приймаємо всіх',
                reject_all_btn: 'Відкинути все',
                close_btn_label: 'Закрити',
                // cookie_table_caption: 'Cookie list',
                cookie_table_headers: [
                    {col1: 'Ім\'я'},
                    {col2: 'Домен'},
                    {col3: 'Закінчення терміну дії'},
                    {col4: 'Опис'}
                ],
                blocks: [
                    {
                        title: 'Використання файлів cookie 📢',
                        description: 'Я використовую файли cookie, щоб забезпечити основні функції веб-сайту та покращити ваш досвід роботи в Інтернеті. Ви можете вибрати для кожної категорії, щоб увімкнути/вимкнути їх, коли захочете. Для отримання більш детальної інформації про файли cookie та інші конфіденційні дані, будь ласка, прочитайте повну <a href="#" class="cc-link">політику конфіденційності</a>.'
                    }, {
                        title: 'Строго необхідні файли cookie',
                        description: 'Ці файли cookie необхідні для належного функціонування мого веб-сайту. Без цих файлів cookie веб-сайт не працюватиме належним чином',
                        toggle: {
                            value: 'necessary',
                            enabled: true,
                            readonly: true          // cookie categories with readonly=true are all treated as "necessary cookies"
                        }
                    }, {
                        title: 'Файли cookie продуктивності та аналітики',
                        description: 'Ці файли cookie дозволяють веб-сайту запам\'ятовувати вибір, який ви робили в минулому',
                        toggle: {
                            value: 'analytics',     // your cookie category
                            enabled: false,
                            readonly: false
                        },
                        cookie_table: [             // list of all expected cookies
                            {
                                col1: '^_ga',       // match all cookies starting with "_ga"
                                col2: 'google.com',
                                col3: '2 years',
                                col4: 'description ...',
                                is_regex: true
                            },
                            {
                                col1: '_gid',
                                col2: 'google.com',
                                col3: '1 day',
                                col4: 'description ...',
                            }
                        ]
                    }
                ]
            }
        },
        'en': {
            consent_modal: {
                title: 'We use cookies!',
                description: 'Hi, this website uses essential cookies to ensure its proper operation and tracking cookies to understand how you interact with it. The latter will be set only after consent. <button type="button" data-cc="c-settings" class="cc-link">Let me choose</button>',
                primary_btn: {
                    text: 'Accept all',
                    role: 'accept_all'              // 'accept_selected' or 'accept_all'
                },
                secondary_btn: {
                    text: 'Reject all',
                    role: 'accept_necessary'        // 'settings' or 'accept_necessary'
                }
            },
            settings_modal: {
                title: 'Cookie preferences',
                save_settings_btn: 'Save settings',
                accept_all_btn: 'Accept all',
                reject_all_btn: 'Reject all',
                close_btn_label: 'Close',
                // cookie_table_caption: 'Cookie list',
                cookie_table_headers: [
                    {col1: 'Name'},
                    {col2: 'Domain'},
                    {col3: 'Expiration'},
                    {col4: 'Description'}
                ],
                blocks: [
                    {
                        title: 'Cookie usage 📢',
                        description: 'I use cookies to ensure the basic functionalities of the website and to enhance your online experience. You can choose for each category to opt-in/out whenever you want. For more details relative to cookies and other sensitive data, please read the full <a href="#" class="cc-link">privacy policy</a>.'
                    }, {
                        title: 'Strictly necessary cookies',
                        description: 'These cookies are essential for the proper functioning of my website. Without these cookies, the website would not work properly',
                        toggle: {
                            value: 'necessary',
                            enabled: true,
                            readonly: true          // cookie categories with readonly=true are all treated as "necessary cookies"
                        }
                    }, {
                        title: 'Performance and Analytics cookies',
                        description: 'These cookies allow the website to remember the choices you have made in the past',
                        toggle: {
                            value: 'analytics',     // your cookie category
                            enabled: false,
                            readonly: false
                        },
                        cookie_table: [             // list of all expected cookies
                            {
                                col1: '^_ga',       // match all cookies starting with "_ga"
                                col2: 'google.com',
                                col3: '2 years',
                                col4: 'description ...',
                                is_regex: true
                            },
                            {
                                col1: '_gid',
                                col2: 'google.com',
                                col3: '1 day',
                                col4: 'description ...',
                            }
                        ]
                    }, {
                        title: 'Advertisement and Targeting cookies',
                        description: 'These cookies collect information about how you use the website, which pages you visited and which links you clicked on. All of the data is anonymized and cannot be used to identify you',
                        toggle: {
                            value: 'targeting',
                            enabled: false,
                            readonly: false
                        }
                    }, {
                        title: 'More information',
                        description: 'For any queries in relation to our policy on cookies and your choices, please <a class="cc-link" href="#yourcontactpage">contact us</a>.',
                    }
                ]
            }
        },
        'hu-HU': {
            consent_modal: {
                title: 'A weboldal sütiket használ!',
                description: 'Tisztelt Felhasználó! Ez a weboldal elengedhetetlen sütiket alkalmaz annak érdekében, hogy megfelelően működjön. Ezek csak beleegyezését követően kerülnek beállításra. <button type="button" data-cc="c-settings" class="cc-link">Beállítások</button>',
                primary_btn: {
                    text: 'Elfogad',
                    role: 'accept_all'
                },
                secondary_btn: {
                    text: 'Elutasít',
                    role: 'accept_necessary'
                }
            },
            settings_modal: {
                title: 'Sütibeállítások',
                save_settings_btn: 'Beállítások mentése',
                accept_all_btn: 'Mindet elfogad',
                reject_all_btn: 'Mindet elutasít',
                close_btn_label: 'Bezár',
                cookie_table_headers: [
                    {col1: 'Név'},
                    {col2: 'Domain'},
                    {col3: 'Lejárati idő'},
                    {col4: 'Leírás'}
                ],
                blocks: [
                    {
                        title: 'Sütihasználat 📢',
                        description: 'Sütiket alkalmazok annak érdekében, hogy biztosítsam a weboldal alapvető funkcionalitásait, és javítsam az online élményét. Minden kategóriáról eldönthet, hogy beleegyezik-e vagy sem. További részletekért a sütikről és más érzékeny adatokról, kérem, olvassa el a teljes <a href="#" class="cc-link">adatvédelmi irányelveket</a>.'
                    }, {
                        title: 'Szigorúan szükséges sütik',
                        description: 'Ezek a sütik elengedhetetlenek weboldala megfelelő működéséhez. Ezek nélkül a sütik nélkül a weboldal nem fog megfelelően működni',
                        toggle: {
                            value: 'szükséges',
                            enabled: true,
                            readonly: true
                        }
                    }, {
                        title: 'Teljesítmény és Analitikai sütik',
                        description: 'Ezek a sütik lehetővé teszik weboldalam számára, hogy emlékezzen korábbi választásaira',
                        toggle: {
                            value: 'analitikai',
                            enabled: false,
                            readonly: false
                        },
                        cookie_table: [
                            {
                                col1: '^_ga',
                                col2: 'google.com',
                                col3: '2 év',
                                col4: 'leírás ...',
                                is_regex: true
                            },
                            {
                                col1: '_gid',
                                col2: 'google.com',
                                col3: '1 nap',
                                col4: 'leírás ...',
                            }
                        ]
                    }, {
                        title: 'Hirdetési és Célzott sütik',
                        description: 'Ezek a sütik információkat gyűjtenek arról, hogy hogyan használja a weboldalt, mely oldalakat látogatta meg és mely linkekre kattintott. Az összes adat anonimizált, és nem használható fel a személyazonossága azonosítására',
                        toggle: {
                            value: 'célzott',
                            enabled: false,
                            readonly: false
                        }
                    }, {
                        title: 'További információk',
                        description: 'Bármilyen kérdés esetén az adatvédelmi irányelveinkkel és a sütikkel kapcsolatos döntéseivel kapcsolatban, kérem, <a class="cc-link" href="#yourcontactpage">lépjen kapcsolatba velünk</a>.',
                    }
                ]
            }
        }
    }
});


